<template>
  <v-row>
    <v-col>
        <v-card v-if="currentImage">
            <v-img
            :src="currentImage"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            contain
            max-height="100"
            >
            <v-card-title
                v-text="$t('components.images.preview.current')"
            ></v-card-title>
            </v-img>
        </v-card>
    </v-col>
    <v-col>
        <v-card v-if="newImage">
            <v-img
            :src="newImage"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            contain
            max-height="100"
            >
            <v-card-title
                v-text="$t('components.images.preview.new')"
            ></v-card-title>
            </v-img>
        </v-card>
    </v-col>
    </v-row>
</template>

<script>
  export default {
    name: 'CurrentAndNewImagePreview',
    props: {
        currentImage: String,
        newImage: String
    },
    data() {
    return {
      error: null,
    };
  },
  }
</script>
