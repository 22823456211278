<template>
  <v-container class="grey lighten-5 mb-6" fluid>
    <v-row>
      <v-col>
        <v-card class="mx-auto form-card-padding">
          <v-toolbar flat>
            <v-toolbar-title class="grey--text">
              {{ $t('product.edit.title', {name:productData.name}) }}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon>
              <v-icon>mdi-barcode</v-icon>
            </v-btn>
          </v-toolbar>

          <v-divider></v-divider>

          <v-form ref="userForm" lazy-validation>
            <v-text-field
              v-model="productData.name"
              :counter="15"
              :rules="validation.nameRules"
              :label="$t('product.edit.name')"
              required
            ></v-text-field>

            <v-text-field
              v-model="productData.description"
              :counter="15"
              :rules="validation.lastnameRules"
              :label="$t('product.edit.description')"
              required
            ></v-text-field>

            <v-select
              v-model="productData.rule"
              :items="productConstants.rules"
              :menu-props="{ maxHeight: '400' }"
              :label="$t('product.edit.rule.label')"
              :hint="$t('product.edit.rule.hint')"
              persistent-hint
            ></v-select>

            <v-select
              v-model="productData.type"
              :items="productConstants.types"
              :menu-props="{ maxHeight: '400' }"
              :label="$t('product.edit.type.label')"
              :hint="$t('product.edit.type.hint')"
              persistent-hint
            ></v-select>

            <v-select
              v-model="productData.group"
              :items="productConstants.groups"
              :menu-props="{ maxHeight: '400' }"
              :label="$t('product.edit.group.label')"
              :hint="$t('product.edit.group.hint')"
              persistent-hint
            ></v-select>

            <v-switch
              v-model="productData.active"
              :label="$t('product.add.active')"
            ></v-switch>

            <product-variations
              :variations="productData.variations"
              v-on:rerender-variations="reRenderVariations()"
              :key="numberOfVariations"
            ></product-variations>

            <v-btn
              style="margin-top: 10px"
              color="success"
              class="mr-4 align-self-lg-end"
              @click="updateProduct()"
            >
            {{ $t('product.edit.controlls.update') }}
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <error-handler :error="error"></error-handler>
  </v-container>
</template>
<script>
import ProductVariations from "./Variations"

export default {
  name: "EditProduct",
  components: {
    ProductVariations
  },
  data() {
    return {
      error: null,
      isLoading: true,
      validation: {
        nameRules: [
          (v) => !!v || "Name is required",
        ],
        description: [
          (v) => !!v || "Lastname is required",
        ],
      },
      productData: {
        name: "",
        description: "",
        rule: null,
        type: null,
        group: null,
        active: null,
        variations: [],
      },
    };
  },
  computed: {
    applicationData() {
      return this.$store.state.applicationData
    },
    productConstants() {
      if(this.applicationData) {
        return this.applicationData.product.constants; 
      }
      return []
    },
    numberOfVariations() {
      return this.productData.variations.length
    }
  },
  mounted() {
    this.$store.dispatch('loadApplicationData')
    this.getProduct()
  },
  methods: {
    getProduct() {
      this.$axios({
        method: "get",
        url: this.$globals.api.product.get + this.$route.params.id,
      })
        .then((response) => {
          this.productData.name = response.data.data.name;
          this.productData.description = response.data.data.description;
          this.productData.rule = response.data.data.rule;
          this.productData.type = response.data.data.type;
          this.productData.group = response.data.data.group;
          this.productData.active = response.data.data.active;

          this.productData.variations = response.data.data.variations;
        })
        .catch((error) => {
          this.error = error.response;
        });
    },
    updateProduct() {
      this.$axios({
        method: "post",
        url: this.$globals.api.product.update  + this.$route.params.id,
        data: this.productData,
      })
        .then(() => {
          this.$router.push({ name: "product/list" });
        })
        .catch((error) => {
          this.error = error.response;
        });
    },
    reRenderVariations() {
      this.getProduct()
    }
  },
};
</script>