<template>
  <v-dialog
    v-model="active"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('product.variations.edit.form.title') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="editVariation()">
            {{ $t('product.variations.edit.form.controlls.update') }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container class="grey lighten-5 mb-6" fluid>
        <v-row>
          <v-col>
            <v-card class="mx-auto form-card-padding">
              <v-form ref="userForm" lazy-validation>
                <v-text-field
                  v-model="variationData.sku"
                  :counter="15"
                  :rules="validation.skuRules"
                  :label="$t('product.variations.edit.form.sku')"
                  required
                ></v-text-field>

                <v-select
                  v-model="variationData.color"
                  :items="productConstants.colors"
                  :menu-props="{ maxHeight: '400' }"
                  :label="$t('product.variations.edit.form.color.label')"
                  :hint="$t('product.variations.edit.form.color.hint')"
                  persistent-hint
                ></v-select>

                <v-file-input
                  :label="$t('product.variations.edit.form.editorImage')"
                  filled
                  prepend-icon="mdi-camera"
                  v-model="variationData.editorImage"
                  id="editorImage"
                  @change="onFileChangeEditorImage"
                  style="margin-top: 20px"
                ></v-file-input>
                <current-and-new-image-preview
                  :currentImage="variationData.editor_image_path"
                  :newImage="previewEditorImage"
                ></current-and-new-image-preview>

                <v-file-input
                  :label="$t('product.variations.edit.form.editorColorPickerImage')"
                  filled
                  prepend-icon="mdi-camera"
                  v-model="variationData.editorColorPickerImage"
                  id="editorColorPickerImage"
                  @change="onFileChangeEditorColorPickerImage"
                  style="margin-top: 20px"
                ></v-file-input>
                <current-and-new-image-preview
                  :currentImage="variationData.editor_color_picker_image_path"
                  :newImage="previewEditorColorPickerImage"
                ></current-and-new-image-preview>

                <v-file-input
                  :label="$t('product.variations.edit.form.canvasImage')"
                  filled
                  prepend-icon="mdi-camera"
                  v-model="variationData.canvasImage"
                  id="canvasImage"
                  @change="onFileChangeCanvasImage"
                  style="margin-top: 20px"
                ></v-file-input>
                <current-and-new-image-preview
                  :currentImage="variationData.canvas_image_path"
                  :newImage="previewCanvasImage"
                ></current-and-new-image-preview>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import CurrentAndNewImagePreview from "../../components/images/CurrentAndNewImagePreview"

export default {
  name: 'EditVariationDialog',
  components: {
    CurrentAndNewImagePreview
  },
  props: {
    setup: Object,
    active: Boolean
  },
  data () {
    return {
      variationData: this.setup,
      validation: {
        skuRules: [v => !!v || 'Name is required']
      },
      previewEditorImage: null,
      previewEditorColorPickerImage: null,
      previewCanvasImage: null,
    }
  },
  watch: {
    setup: function (val) {
      this.variationData = val
    },
  },
  computed: {
    applicationData () {
      return this.$store.state.applicationData
    },
    productConstants () {
      if (this.applicationData) {
        return this.applicationData.product.constants
      }
      return []
    }
  },
  methods: {
    onFileChangeEditorImage () {
      if (this.variationData.editorImage != null) {
        this.previewEditorImage = URL.createObjectURL(this.variationData.editorImage)
      } else {
        this.previewEditorImage = null
      }
    },
    onFileChangeCanvasImage () {
      if (this.variationData.canvasImage != null) {
        this.previewCanvasImage = URL.createObjectURL(this.variationData.canvasImage)
      } else {
        this.previewCanvasImage = null
      }
    },
    onFileChangeEditorColorPickerImage () {
      if (this.variationData.editorColorPickerImage != null) {
        this.previewEditorColorPickerImage = URL.createObjectURL(this.variationData.editorColorPickerImage)
      } else {
        this.previewEditorColorPickerImage = null
      }
    },
    editVariation () {
      var formData = new FormData()

      var editorImage = document.querySelector('#editorImage')
      var editorColorPickerImage = document.querySelector('#editorColorPickerImage')
      var canvasImage = document.querySelector('#canvasImage')

      formData.append('editorImage', editorImage.files[0])
      formData.append('editorColorPickerImage', editorColorPickerImage.files[0])
      formData.append('canvasImage', canvasImage.files[0])

      formData.append('sku', this.variationData.sku)
      formData.append('color', this.variationData.color)

      this.$axios({
        method: 'post',
        url: this.$globals.api.product.variation.update + this.variationData.id,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(() => {
          this.cleanImageSelection()
          this.$emit('close-dialog')
          this.$emit('rerender-variations')
        })
        .catch(error => {
          this.error = error.response
        })
    },
    cleanImageSelection() {
      this.previewEditorImage = null
      this.previewEditorColorPickerImage = null
      this.previewCanvasImage = null
    },
    closeDialog() {
      this.cleanImageSelection()
      this.$emit('close-dialog')
    }
  }
}
</script>
