<template>
  <v-container class="grey lighten-5 mb-6" fluid>
    <v-row>
      <v-col>
        <v-card class="mx-auto form-card-padding">
          <v-toolbar flat>
            <v-toolbar-title class="grey--text">
              {{ $t('product.variations.title') }}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon>
              <v-icon>mdi-basket-fill</v-icon>
            </v-btn>
          </v-toolbar>

          <v-divider></v-divider>

          <v-expansion-panels>
            <v-expansion-panel key="Create">
              <v-expansion-panel-header>
                {{ $t('product.variations.add.title') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-form ref="userForm" lazy-validation>
                  <v-text-field
                    v-model="variationData.sku"
                    :counter="15"
                    :rules="validation.skuRules"
                    :label="$t('product.variations.add.sku')"
                    required
                  ></v-text-field>

                  <v-select
                    v-model="variationData.color"
                    :items="productConstants.colors"
                    :menu-props="{ maxHeight: '400' }"
                    :label="$t('product.variations.add.color.label')"
                    :hint="$t('product.variations.add.color.hint')"
                    persistent-hint
                  ></v-select>

                  <v-file-input
                    :label="$t('product.variations.add.editorImage')"
                    filled
                    prepend-icon="mdi-camera"
                    v-model="variationData.editorImage"
                    id="editorImage"
                    @change="onFileChangeEditorImage"
                    style="margin-top: 20px"
                  ></v-file-input>

                  <v-card v-if="previewEditorImage">
                    <v-img
                      :src="previewEditorImage"
                      class="white--text align-end"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      contain
                      max-height="100"
                    >
                      <v-card-title v-text="$t('product.variations.add.previewEditorImage')"></v-card-title>
                    </v-img>
                  </v-card>

                  <v-file-input
                    :label="$t('product.variations.add.editorColorPickerImage')"
                    filled
                    prepend-icon="mdi-camera"
                    v-model="variationData.editorColorPickerImage"
                    id="editorColorPickerImage"
                    @change="onFileChangeEditorColorPickerImage"
                    style="margin-top: 20px"
                  ></v-file-input>

                  <v-card v-if="previewEditorColorPickerImage">
                    <v-img
                      :src="previewEditorColorPickerImage"
                      class="white--text align-end"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      contain
                      max-height="100"
                    >
                      <v-card-title v-text="$t('product.variations.add.previewEditorColorPickerImage')"></v-card-title>
                    </v-img>
                  </v-card>

                  <v-file-input
                    :label="$t('product.variations.add.canvasImage')"
                    filled
                    prepend-icon="mdi-camera"
                    v-model="variationData.canvasImage"
                    id="canvasImage"
                    @change="onFileChangeCanvasImage"
                    style="margin-top: 20px"
                  ></v-file-input>

                  <v-card v-if="previewCanvasImage">
                    <v-img
                      :src="previewCanvasImage"
                      class="white--text align-end"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      contain
                      max-height="100"
                    >
                      <v-card-title v-text="$t('product.variations.add.previewCanvasImage')"></v-card-title>
                    </v-img>
                  </v-card>

                  <v-btn
                    style="margin-top: 10px"
                    color="success"
                    class="mr-4 align-self-lg-end"
                    @click="createVariation()"
                  >
                  {{ $t('product.variations.add.controlls.create') }}
                  </v-btn>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel key="Current">
              <v-expansion-panel-header>
                {{ $t('product.variations.edit.title') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  :headers="headers"
                  :items="variations"
                  :sort-by="[]"
                  :sort-desc="[false, true]"
                  multi-sort
                  class="elevation-1"
                >
                  <template v-slot:[`item.editor_image_path`]="props">
                    <v-img :src="props.item.editor_image_path" class="product-variation-datatable-image"></v-img>
                  </template>

                  <template v-slot:[`item.editor_color_picker_image_path`]="props">
                    <v-img :src="props.item.editor_color_picker_image_path" class="product-variation-datatable-image"></v-img>
                  </template>

                  <template v-slot:[`item.canvas_image_path`]="props">
                    <v-img :src="props.item.canvas_image_path" class="product-variation-datatable-image"></v-img>
                  </template>

                  <template v-slot:[`item.controls`]="props">
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="warning"
                      @click="editVariation(props.item)"
                    >
                      <v-icon dark>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="error"
                      @click="openDeleteVariationDialog(props.item)"
                    >
                      <v-icon dark>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels> 
        </v-card>
      </v-col>
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">{{ $t('product.variations.delete.title') }}</v-card-title>
          <v-card-text>{{ $t('product.variations.delete.description') }}</v-card-text>
          <v-card-actions class="justify-center">
            <v-row class="text-center" style="margin:0px">
              <v-col >
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="error"
                  text
                  @click="deleteVariation()"
                >
                  <v-icon dark>mdi-delete</v-icon>
                  {{ $t('product.variations.delete.controlls.delete') }}
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  text
                  @click="closeDeleteVariationDialog()"
                >
                  <v-icon dark>mdi-close-circle</v-icon>
                  {{ $t('product.variations.delete.controlls.cancel') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

      
      <edit-variation-dialog v-if="editVariationData"
        :setup="editVariationData"
        :active="dialogEdit"
        v-on:close-dialog="dialogEdit = false"
        v-on:rerender-variations="reRender()"
      ></edit-variation-dialog>
    </v-row>
    <error-handler :error="error"></error-handler>
  </v-container>
</template>
<script>
import EditVariationDialog from "./EditVariation"

export default {
  name: "AddProductVariations",
  components: {
    EditVariationDialog
  },
  props: {
      variations : Array
  },
  data() {
    return {
      validation: {
        skuRules: [
          (v) => !!v || "Name is required",
        ],
      },
      variationData: {
        sku: "",
        color: null,
        editorImage: null,
        editorColorPickerImage: null,
        canvasImage: null,
      },
      editVariationData: null,
      previewEditorImage: null,
      previewEditorColorPickerImage: null,
      previewCanvasImage: null,
      deletingVariation: null,
      dialog: false,
      dialogEdit: false,
      error: null,
      isLoading: true,
      headers: [
        {
          text: this.$t('product.variations.edit.list.sku'),
          align: 'start',
          value: 'sku'
        },
        {
          text: this.$t('product.variations.edit.list.color'),
          align: 'start',
          value: 'color'
        },
        {
          text: this.$t('product.variations.edit.list.editorImage'),
          align: 'start',
          value: 'editor_image_path'
        },
        {
          text: this.$t('product.variations.edit.list.editorColorPickerImage'),
          align: 'start',
          value: 'editor_color_picker_image_path'
        },
        {
          text: this.$t('product.variations.edit.list.canvasImage'),
          align: 'start',
          value: 'canvas_image_path'
        },
        {
          text: this.$t('product.variations.edit.list.actions'),
          value: 'controls',
          sortable: false,
          align: 'center'
        }
      ],
    };
  },
  computed: {
    applicationData() {
      return this.$store.state.applicationData
    },
    productConstants() {
      if(this.applicationData) {
        return this.applicationData.product.constants; 
      }
      return []
    }
  },
  methods: {
    onFileChangeEditorImage() {
      if (this.variationData.editorImage != null) {
        this.previewEditorImage = URL.createObjectURL(this.variationData.editorImage);
      } else {
        this.previewEditorImage = null;
      }
    },
    onFileChangeCanvasImage(){
      if (this.variationData.canvasImage != null) {
        this.previewCanvasImage = URL.createObjectURL(this.variationData.canvasImage);
      } else {
        this.previewCanvasImage = null;
      }
    },
    onFileChangeEditorColorPickerImage(){
      if (this.variationData.editorColorPickerImage != null) {
        this.previewEditorColorPickerImage = URL.createObjectURL(this.variationData.editorColorPickerImage);
      } else {
        this.previewEditorColorPickerImage = null;
      }
    },
    createVariation() {
      var formData = new FormData();

      var editorImage = document.querySelector("#editorImage");
      var editorColorPickerImage = document.querySelector("#editorColorPickerImage");
      var canvasImage = document.querySelector("#canvasImage");

      formData.append("editorImage", editorImage.files[0]);
      formData.append("editorColorPickerImage", editorColorPickerImage.files[0]);
      formData.append("canvasImage", canvasImage.files[0]);

      formData.append("sku", this.variationData.sku);
      formData.append("color", this.variationData.color);

      this.$axios({
        method: "post",
        url: this.$globals.api.product.variation.create +  this.$route.params.id,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          this.reRender()
        })
        .catch((error) => {
          this.error = error.response;
        });
    },
    openDeleteVariationDialog(variation) {
      this.deletingVariation = variation
      this.dialog = true
    },
    closeDeleteVariationDialog() {
      this.deletingVariation = null
      this.dialog = false;
    },
    deleteVariation() {
      this.$axios({
        method: "post",
        url: this.$globals.api.product.variation.delete + this.deletingVariation.id,
      })
        .then(() => {
          this.deletingVariation = null
          this.dialog = false
          this.isLoading = true
          this.reRender()
        })
        .catch((error) => {
          this.error = error.response;
        });
    },
    editVariation(variation) {
      this.editVariationData = variation
      this.dialogEdit = true
    },
    reRender(){
      this.$emit('rerender-variations')
    }
  },
};
</script>